var __jsx = React.createElement;
/** @format */

import React from 'react';
import { pointsTermCalculator, actualPointsCalculator } from "../offerUtils";
import { CardDetail, PointsEarn, PointsTerm, DaysLeft, PrevPoints, EarnContainer } from "./style";
var CardDetails = function CardDetails(props) {
  var formatCurrency = function formatCurrency(number) {
    return Number(number).toLocaleString();
  };
  var offerType = props.offerType,
    dollarSpend = props.dollarSpend,
    daysLeft = props.daysLeft,
    discountType = props.discountType;
  var pointTerms = pointsTermCalculator(offerType, dollarSpend, discountType);
  var _actualPointsCalculat = actualPointsCalculator(props),
    showPrevPoints = _actualPointsCalculat.showPrevPoints,
    actualPoints = _actualPointsCalculat.actualPoints;
  return __jsx(CardDetail, {
    offerType: offerType
  }, __jsx("div", null, __jsx(EarnContainer, null, showPrevPoints ? __jsx(PrevPoints, null, "was ", showPrevPoints) : null, __jsx(PointsEarn, null, actualPoints === 'null' ? 0 : formatCurrency(actualPoints), ' ', actualPoints > 1 ? ' PTS ' : ' PT '), __jsx(PointsTerm, null, pointTerms.replace('spent', '')), __jsx(DaysLeft, null, daysLeft))));
};
CardDetails.defaultProps = {
  offerType: '',
  dollarSpend: '',
  daysLeft: '',
  discountType: ''
};
export default CardDetails;