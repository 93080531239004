import styled from 'styled-components';
import { layoutSizes } from "../../constants/css";
import { Container } from "../_global";
import InfoBox from "../InfoBox";
export var StyledCardWrapper = styled.div.withConfig({
  displayName: "style__StyledCardWrapper",
  componentId: "sc-384dl9-0"
})(["margin-bottom:", ";position:relative;overflow:hidden;padding:", ";.slick-disabled{display:none !important;}.slick-list{overflow:initial;}"], function (props) {
  return !props.noMargin && '2rem';
}, function (props) {
  return props.noMargin ? '0' : '0.625rem 0';
});
export var CardPrevArrowContainer = styled.div.withConfig({
  displayName: "style__CardPrevArrowContainer",
  componentId: "sc-384dl9-1"
})(["display:none;@media only screen and (min-width:", "){display:flex;justify-content:center;align-items:center;height:50px;width:50px;background-color:rgb(85,85,85);border-radius:50%;position:absolute;top:35%;z-index:1;cursor:pointer;margin-left:15px;}"], layoutSizes.ipadPortraitWidth);
export var CardNextArrowContainer = styled(CardPrevArrowContainer).withConfig({
  displayName: "style__CardNextArrowContainer",
  componentId: "sc-384dl9-2"
})(["@media only screen and (min-width:", "){top:35%;right:0;margin-right:15px;}"], layoutSizes.ipadPortraitWidth);
export var ViewAllContainer = styled.div.withConfig({
  displayName: "style__ViewAllContainer",
  componentId: "sc-384dl9-3"
})(["width:50px;margin:2rem;"]);
export var McoContainer = styled(Container).withConfig({
  displayName: "style__McoContainer",
  componentId: "sc-384dl9-4"
})(["padding:", ";"], function (props) {
  return props.noMargin ? '0.625rem 0' : 'inherit inherit 1rem';
});
export var HowItWorks = styled(InfoBox).withConfig({
  displayName: "style__HowItWorks",
  componentId: "sc-384dl9-5"
})(["display:none;@media only screen and (min-width:", "){display:", ";}"], layoutSizes.desktopWidth, function (props) {
  return props.tooltip ? 'none' : 'block';
});
export var TextCenter = styled.div.withConfig({
  displayName: "style__TextCenter",
  componentId: "sc-384dl9-6"
})(["text-align:center;"]);